import React from 'react';
import './index.css'; // Assuming your CSS file is named index.css

function App() {
  return (
    <div className="d-flex flex-column h-100">
      <main className="flex-shrink-0">
        {/* Navigation */}
        <nav className="navbar navbar-expand-lg navbar-light bg-white py-3">
          <div className="container px-5">
            <a className="navbar-brand" href="index.html">
              <span className="fw-bolder text-primary fs-8">Pragmatize.co</span>
            </a>
          </div>
        </nav>

        {/* Header */}
        <header className="py-5">
          <div className="container px-5 pb-5">
            <div className="row gx-5 align-items-center">
              <div className="col-xxl-5">
                <div className="text-center text-xxl-start">
                  <div className="badge bg-gradient-primary-to-secondary text-white mb-4">
                    AI · Education · Impact
                  </div>
                  <div className="fs-3 fw-light text-muted">Personalized AI Educator</div>
                  <h1 className="display-3 fw-bolder mb-5">
                    <span className="text-gradient d-inline">A new way of Learning</span>
                  </h1>
                  <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                  <a className="btn btn-primary btn-lg px-5 py-3 me-sm-3 fs-8 fw-bolder" href="mailto:info@pragmatize.co?subject=Joining the Waitlist">Join the waitlist</a>

                  </div>
                </div>
              </div>
              <div className="col-xxl-7">
                {/* Header profile picture */}
                <div className="d-flex justify-content-center mt-5 mt-xxl-0">
                  <div className="profile">
                    <img className="profile-img" src="image.png" alt="..."/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* About Section */}
        <section className="bg-light py-5">
          <div className="container px-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-xxl-8">
                <div className="text-center my-5">
                  <h2 className="display-5 fw-bolder">
                    <span className="text-gradient d-inline">We are</span>
                  </h2>
                  <p className="lead fw-light mb-4">build a platform that makes learning engaging and interesting.</p>
                  <p className="text-muted">Introducing the future of strategic decision-making: our cutting-edge AI platform. Crafted for visionary businesses, this tool simulates complex scenarios, offering a sneak peek into the impact of your choices before you make them. Ready to lead with confidence and outsmart the future?</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-white py-4 mt-auto">
        <div className="container px-5">
          <div className="row align-items-center justify-content-between flex-column flex-sm-row">
            <div className="col-auto">
              <div className="small m-0">Copyright &copy; Pragmatize.co 2024</div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
